<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="col1Items"
            itemText="codeName"
            itemValue="code"
            label="연/원료 구분"
            name="col1"
            v-model="searchParam.col1">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="col2Items"
            itemText="codeName"
            itemValue="code"
            label="연료구분"
            name="col2"
            v-model="searchParam.col2">
          </c-select>
        </div> -->
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="사업장별 온실가스"
          tableId="processManual"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          selection="multiple"
          rowKey="col0"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLADD" icon="add" />
              <c-btn label="LBLREMOVE" icon="remove" />
              <c-btn label="LBLSAVE" icon="save" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-air-report-manage',
  data() {
    return {
      col1Items: [
        { code: '1', codeName: '연료' },
        { code: '2', codeName: '원료' },
      ],
      col2Items: [
        { code: '1', codeName: '액체연료연소' },
        { code: '2', codeName: '고체연료연소' },
      ],
      grid: {
        columns: [],
        data: [],
      },
      searchParam: {
        plantCd: null,
        col1: null,
        col2: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.grid.columns = [
        {
          name: 'plantCd',
          field: 'plantCd',
          label: 'LBLPLANT',
          align: 'center',
          type: 'plant',
          sortable: true,
          style: 'width: 150px',
        },
        {
          name: 'col00',
          field: 'col00',
          label: '코드',
          align: 'center',
          type: 'text',
          style: 'width: 150px',
          sortable: true,
        },
        {
          name: 'col0',
          field: 'col0',
          label: '온실가스 명',
          align: 'center',
          type: 'text',
          sortable: true,
        },
        {
          name: 'col1',
          field: 'col1',
          label: '화학식',
          align: 'center',
          type: 'text',
          style: 'width: 150px',
          sortable: true,
        },
        {
          name: 'col2',
          field: 'col2',
          label: 'GWP(지구온난화지수)',
          align: 'center',
          type: 'number',
          style: 'width: 150px',
          sortable: true,
        },
      ]
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          plantCd: '1000',
          col00: '01',
          col0: '이산화탄소', 
          col1: 'CO2', 
          col2: '1', 
        },
        {
          plantCd: '1000',
          col00: '02',
          col0: '메탄', 
          col1: 'CH4', 
          col2: '21', 
        },
        {
          plantCd: '1000',
          col00: '03',
          col0: '이산화질소', 
          col1: 'N2O', 
          col2: '310', 
        },
      ];
    },
  }
};
</script>
